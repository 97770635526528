<template>
  <!-- 折叠展开高度设置 -->

  <div class="navHeader">
    <el-menu
        class="el-menu-vertical-demo"
        background-color="#233040"
        text-color="#909399"
        active-text-color="#FD9E03"
        :default-active="defaultActiveMenu"
        :collapse="isCollapse"
        router
        ref="menu"
        @select="listSelectMenu"
        unique-opened
        style="height: calc(100vh - 47px); padding: 0; margin: 0;width: 170px;overflow: auto"
    >
      <el-menu-item style="text-align: center;background-color:#233040 !important;border: none;">
        <i
            :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            @click="setIsCollapse"
            style="cursor: pointer;"
        ></i>
      </el-menu-item>
      <el-submenu
          :index="'/' + item.path"
          v-for="(item, index) in currentlist"
          :key="index"
      >
        <template slot="title">
          <i :class="chanIcon(item.name)"></i>
          <span>{{ item.name }}</span>
        </template>

        <el-menu-item-group>
          <el-menu-item
              v-for="(childitem, childindex) in item.children"
              :key="childindex"
              :index="'/home' + childitem.path"
          >
            <span>{{ childitem.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
// 引入封装好的api.js
import {apiServe} from "@/api/api";
import lodash from 'lodash';
import {EventBus} from "@/store/eventBus.js";

export default {
  data() {
    return {
      menuForm: {
        name: "",
        path: "",
        children: [],
      },
      name1: "",
      name: "",
      type: 1,
      logo: "",
      defaultActiveMenu: '',
      menulist: [],
      currentlist: [],
      isCollapse: false,
      storageList: [],
      iconList: [
        {
          name: "系统设置",
          icon: "el-icon-setting",
        },
        {
          name: "店铺设置",
          icon: "el-icon-setting",
        },
        {
          name: "首页",
          icon: "el-icon-house",
        },
        {
          name: "角色管理",
          icon: "el-icon-user",
        },
        {
          name: "数据申请",
          icon: "el-icon-files",
        },
        {
          name: "订单",
          icon: "el-icon-tickets",
        },
        {
          name: "商品",
          // icon: "el-icon-shopping-cart-2",
          icon: "el-icon-goods",
        },
        {
          name: "物流设置",
          icon: "el-icon-truck",
        },
        {
          name: "财务",
          icon: "el-icon-s-finance",
        },
        {
          name: "硬件设置",
          icon: "el-icon-printer",
        },
        {
          name: "使用手册",
          icon: "el-icon-notebook-1",
        },

        {
          name: "产品",
          icon: "el-icon-box",
        },
        {
          name: "供应商",
          icon: "el-icon-user",
        },
        {
          name: "客户",
          icon: "el-icon-user",
        },
        {
          name: "仓库",
          icon: "el-icon-office-building",
        },

        {
          name: "出/入库",
          icon: "el-icon-truck",
        },
        {
          name: "库存盘点",
          icon: "el-icon-document",
        },




        {
          name: "设置",
          icon: "el-icon-setting",
        },

      ],
      setPathSign:0,
    };
  },
  methods: {
    chanIcon(item) {
      for (var i = 0; i < this.iconList.length; i++) {
        if (item == this.iconList[i].name) {
          return this.iconList[i].icon;
        }
      }
    },
    // 侧边栏展开和收起
    setIsCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    listSelectMenu(aa) {
      console.log('aaa', aa)
    },
    // 获取菜单列表
    async getMenu() {
      let res = await apiServe.menu();
      this.menulist = res.menu;

      for (const menu of this.menulist) {
        if (menu.name === '仓储') {
          this.storageList = menu.children;
        }
        // 去除菜单选项显示
        if (menu.children) {
          menu.children = menu.children.filter(me => me.path !== '/menu');
        }
      }
      this.menulist = this.menulist.filter(me => me.name !== '仓储');
      this.name1 = res.menu[0].name;
      this.currentlist = lodash.cloneDeep(this.menulist);

      let currentMenu = localStorage.getItem("currentMenu");
      if (Number(currentMenu) === 1 || Number(currentMenu) === 2) {
        // console.log(this.type,'this.type',Number(currentMenu),'Number(currentMenu)')
        if (this.type !== Number(currentMenu)) {
          this.switchMenu(0, true);
        } else {
          this.switchMenu(0, false);
        }
      } else {
        this.switchMenu(0, false);
      }

    },
    switchMenu(top, setType = true) {
      console.log('触发菜单切换','top',top,'setType', setType,'this.type', this.type);

      let that = this;
      // 页面刷新 此时以路径为准 换模块
      function autoSelect(){
        let cPath = that.$route.path;
        let bFor = 0;
        // 判断一下应该显示哪个
        for (const storageListItem of that.storageList) {
          if(bFor === 0){
            if (storageListItem.children && storageListItem.children.length > 0) {
              for (const storageListItemElement of storageListItem.children) {
                if("/home"+storageListItemElement.path === cPath){
                  that.setMenu(2);
                  bFor = 1;
                  break;
                }
              }
            }
          }
        }
        if(bFor === 0){
          for (const menulistItem of that.menulist) {
            if(bFor === 0) {
              if (menulistItem.children && menulistItem.children.length > 0) {
                for (const menulistItemElement of menulistItem.children) {
                  if ("/home" + menulistItemElement.path === cPath) {
                    that.setMenu(1);
                    bFor = 1;
                    break;
                  }
                }
              }
            }
          }
        }
      }

      let path = this.$route.path;
      let openMenus = '';
      if (setType) {
        if (this.type === 2) {
          this.setMenu(1);
          // if (top === 1) {
          if(Number(this.setPathSign) === 0) {
            path = "/home/index";
          }
          //   openMenus = "//indexMenu";
          // }

        } else {
          this.setMenu(2);
          // if (top === 1) {
          if(Number(this.setPathSign) === 0){
            path = "/home/strindex";
          }

          //   openMenus = "//indexMenu";
          // }
        }
        if(top === 1){
          // 点击切换 以模块为准 跳路径
          if (this.$route.path !== path) {
            this.$router.push({path: path});
          }
        }else {
          autoSelect();
        }

      }else {
        autoSelect();
        // 同步显示文字
        // if(Number(this.type) === 2){
        //   this.$store.commit(
        //       "changeTypeText",
        //       '仓储'
        //   );
        // }
        // if(Number(this.type) === 1){
        //   this.$store.commit(
        //       "changeTypeText",
        //       '商城'
        //   );
        // }
      }

      // 过滤权限
      let userPerms = localStorage.getItem("userPerms");
      if (userPerms) {
        userPerms = JSON.parse(userPerms);
        for (const menu of this.currentlist) {
          if (menu.children && menu.children.length > 0) {
            menu.children = menu.children.filter(me => !me.perm || userPerms.includes(me.perm)
            );
          }
        }
        this.currentlist = this.currentlist.filter(me => (me.children && me.children.length > 0));
      }



      // 同步选中项
      setTimeout(() => {
        console.log('切换了')
        that.setDefaultActiveMneu(openMenus);
      }, 200);
    },
    // 切换方法封装
    setMenu(type=0){
      if(type === 1){
        this.type = 1;
        this.currentlist = lodash.cloneDeep(this.menulist);
        localStorage.setItem("currentMenu", '1')
        this.$store.commit(
            "changeTypeText",
            '商城'
        );
      }else if(type === 2){
        this.currentlist = lodash.cloneDeep(this.storageList);
        this.type = 2;
        localStorage.setItem("currentMenu", '2')
        this.$store.commit(
            "changeTypeText",
            '仓储'
        );
      }

    },
    // 同步选中项
    setDefaultActiveMneu(openMenus = '') {
      console.log('已同步选中项')
      this.defaultActiveMenu = this.$route.path;
      let that = this;
      setTimeout(() => {
        that.$refs.menu.activeIndex = that.$route.path;
        let openMenu = [];
        for (const menuItem of that.currentlist) {
          for (const menuItemElement of menuItem['children']) {
            if("/home" + menuItemElement.path === that.$route.path){
              openMenu[0] = "/" + menuItem.path;
              break;
            }
          }
        }
        that.$refs.menu.openedMenus = openMenu;
      }, 100);
    }
  },
  created() {
    EventBus.$on('some-event', (data) => {
      console.log('data.top', data)
      if(data.index === 1){
        this.type = 2;
      }
      if(data.index === 2){
        this.type = 1;
      }
      this.setPathSign = data?.status ?? 0;
      let status = true;
      console.log('this.setPathSign',this.setPathSign)
      // if(data?.status === 1){
      //   status = false;
      // }
      this.switchMenu(data.top, status)
    });
    EventBus.$on('menu-res', this.getMenu);
    // this.$store.commit("changeIsCollapse", false);
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.setDefaultActiveMneu();
    }, 500)
  },
  beforeDestroy() {
    // 清理事件监听器
    EventBus.$off('some-event', this.switchMenu);
    EventBus.$off('menu-res', this.getMenu);
  },
};
</script>
<style lang="scss" scoped>
body,
html {
  height: 100%;
}

.navHeader ::v-deep {
  // height: 100vh;

  // .el-menu-vertical-demo:not(.el-menu--collapse) {
  //   width: 160px;
  //   min-height: 400px;
  // }

  .el-submenu .el-menu-item {
    height: 40px;
    line-height: 40px;
    padding: 0 42px;
    min-width: 0;
  }

  .navBtn {
    display: flex;
  }
  .el-submenu__title{
    height: 50px;
    line-height: 50px;
  }
}

.el-menu-item.is-active {
  margin: 0 5px;
  border-radius: 2px;
  border: #FD9E03 solid 1px;
  line-height: 38px !important;
  //height: 38px !important;
  background-color: rgba(253, 158, 3, 0.3) !important;
}

// 侧边栏调整
::v-deep .el-submenu__title {
  padding-right: 45px;
}

// ::v-deep .el-menu——collapse{
//   padding-right: 0 !important;
// }
::v-deep .el-menu--collapse {
  width: 68px !important;
}

// .navBtn {
//   display: flex;
// }

// .el-menu {
//   background-color: #f3f3f3;
//   border-right: 0;
//   text-align: left;
//   color: #fff;
//   height: 100%;
// }

// .control-btn-small {
//   width: 50px !important;
// }

// .navHeader {
//   width: 145px;
//   height: 95vh;
//   background-color: #F3F3F3;
// }

// .el-container {
//   // 5.布局容器 高度100%
//   height: 100%;

//   .el-aside {
//     // 6.侧边栏 高度100%
//     // height: 100%;
//     // background-color: #f3f3f3;

//     // .el-menu {
//     //     background-color: #f3f3f3;
//     //     border-right: 0;
//     //     text-align: left;
//     //     color: #fff;
//     // }
//     overflow: hidden !important;
//     // height: 94vh;
//   }

//   ::v-deep .el-main {
//     padding: 0;

//     .el-tag {
//       border: none !important;
//       background-color: #fafafa;
//       height: 32px;
//       line-height: 32px;
//     }
//   }
// }

// // 一级菜单样式
// .el-menu .el-menu-item,
// .el-menu .el-submenu__title {
//   height: 50px;
//   line-height: 50px;

//   i {
//     color: #000;
//   }
// }

// // 鼠标移开
// .el-menu-item.is-active {
//   // color: #000;
//   background-color: #c5ccd7;
//   color: #6d829f;
// }

// // 二级菜单
// .el-menu .el-menu-item-group {
//   background-color: #ebecee;
// }

// // 菜单点亮交互样式
// .el-menu .el-menu-item.is-actives,
// .el-menu .el-submenu__title.span-active {
//   background-color: #c5ccd7;
//   color: #fff;
// }

// ::v-deep .el-menu-item-group__title {
//   padding: 0;
// }

// .el-menu .el-menu-item span {
//   color: 0;
// }

// .el-menu .el-menu-item:hover,
// .el-menu .el-menu-item:focus,
// ::v-deep .el-menu .el-submenu__title:hover {
//   background-color: #c5ccd7;
//   color: #6d829f;
// }

// ::v-deep .el-menu .el-submenu__title i {
//   color: #000;
// }

// // 箭头
// ::v-deep .el-icon-d-arrow-right {
//   padding: 10px 9px;
//   margin: auto;
// }

// .control-btn {
//   display: inline-block;
//   // text-align: right;
//   color: #fff;
//   background-color: #233040;
//   width: 145px;
//   height: 32px;
//   font-size: 12px;
//   line-height: 30px;
//   display: flex;

//   img {
//     margin: 5px;
//     vertical-align: middle;
//     width: 30px;
//     height: 20px;
//   }
// }
</style>